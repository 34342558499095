<template>
  <div v-if="page_loading">
    <Loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <Loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        {{use_language.freight}}
      </v-card-title>
    </v-card>

    <!-- ค่าขนส่ง -->
    <v-card elevation="1">
      <v-card-title>
          <v-icon size="20" color="red darken-3">fa-truck</v-icon><span class="my-3 ml-3">ขนส่งของฉัน</span>
          <v-spacer/>
          <v-btn :elevation="1" color="primary" @click="handleOpenDialogEditExpress(null,false)">
            <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          <span class="fn-14"><b> เพิ่มขนส่ง </b></span>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>

<div>
  <v-row class="mt-2 mx-0">
    <v-col cols="12" class=" px-0">
      <!-- PC -->
      <v-data-table
        :headers="my_express.headers"
        :key="0"
        :items="my_express.dataset"
        hide-default-footer
        item-key="id"
        :items-per-page="10000"
        class="elevation-0 packhai-border-table"
      >
        <template v-slot:item.no="{ item }">
          {{ my_express.dataset.indexOf(item) + 1 }}
        </template>
        <template v-slot:item.photoLink="{ item }">
          <v-row >
          <v-col cols="12" align="left" >
            <img :src="item.expressCompanyLogo" width="60px"   />
          </v-col>
      
        </v-row>

    
        </template>
        <template v-slot:item.Name="{ item }">
        
        <v-row >
          <v-col cols="12" align="left" >
            <span v-if="item.isOasysAccount">
              <img  src="@/assets/logo_oasys.png" width="20px" height="20px" />{{ 'Oasys account' }}
              <v-chip v-show="item.isDefault" class="chip-set-13" color="teal" dark> ค่าเริ่มต้น </v-chip>
           </span>
            <span v-else >{{ item.logisticAccountShortName }}  <v-chip v-show="item.isDefault" class="chip-set-13" color="teal" dark> ค่าเริ่มต้น </v-chip></span>
          </v-col>
        </v-row>
        </template>
        <template v-slot:item.COD="{ item }">
          <v-icon v-if="item.isAllowCod" color="success">mdi-check-circle</v-icon>
          <v-icon v-else-if="item.isAllowCod !=null" color="success">mdi-close-circle</v-icon>
  
        </template>
        <template v-slot:item.isActive="{ item }">
        <v-row justify="center">
          <a @click="handleUpdateIsActive(item)">
            <v-switch  v-model="item.isActive" readonly color="success" ></v-switch>
          </a>
        </v-row>
      </template>
      <template v-slot:item.viewDialogMyexpress="{ item }">
        <a 
        v-if="![0,8,9,15].includes(item.expressCompanyId)"
        @click="handleOpenDialogExpress(item,1)">รายละเอียด</a>
      </template>
      <template v-slot:item.viewDialogPackSetting="{ item }">
        <a @click="handleOpenDialogExpress(item,2)">ตั้งค่าการแพ็ค</a>
      </template>
      <template v-slot:item.statusMessage="{ item }">
        <v-row >
          <v-col cols="12" align="left" >
            <span v-if="item.statusMessage === 'พร้อมใช้งาน'"><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon> พร้อมใช้งาน</span>
            <span v-else-if="item.statusMessage === 'ยังไม่พร้อมใช้งาน ต้องตั้งค่า Account ขนส่งเพิ่มเติม'"><v-icon size="10" color="error">mdi-checkbox-blank-circle</v-icon> ยังไม่พร้อมใช้งาน<br/>ต้องตั้งค่า Account ขนส่งเพิ่มเติม</span>
            <span v-else-if="item.statusMessage === 'พร้อมใช้งานแล้วแต่ยังไม่เปิดใช้'"><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon> พร้อมใช้งาน<br/>แต่ยังไม่เปิดใช้</span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.Edit="{ item }">
          <a v-if="![0,8,9,15,16].includes(item.expressCompanyId)" @click="handleOpenDialogEditExpress(item,true)" style="display:flex ;flex-direction: column;">
              <v-icon color="orange darken-3" size="18" >fa-edit</v-icon>
          </a>
        </template>
        <template v-slot:item.Delete="{ item }">
            <a v-if="item.isAllowDelete" @click="handleDeleteMyExpreesClick(item)"
              ><v-icon size="18" color="red darken-3">fa-trash-alt</v-icon></a
            >
            <a v-else ><v-icon size="18" disabled> </v-icon></a  >
        </template>
      </v-data-table>

        </v-col>
      </v-row>
      </div>

      </v-card-text>
    </v-card>

        <!-- แก้ไขหรือเพิ่มขนส่งของฉัน -->
    <v-dialog v-model="my_express_edit.dialog" scrollable max-width="70%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <!-- this.my_express.exp
            ressLogoDialog  -->
          <v-icon size="24" color="success">mdi-truck-fast</v-icon> 
          <span v-if="!my_express_edit.isUpdate" class="ml-3">เพิ่มขนส่ง</span>
          <span v-else class="ml-3">แก้ไขขนส่ง</span>
          <v-spacer/>
          <v-btn icon @click="my_express_edit.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">

  <v-form ref="formAddExpress"  lazy-validation>
  <v-row>
    <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
      <span class="fn-14"> ขนส่ง : </span>
    </v-col>
    <v-col cols="12" md="8" class="pt-1 pb-2">
      <v-select 
              v-if= "!my_express_edit.isUpdate"
               v-model="my_express_edit.expressID"
              :items="my_express_edit.expressName"
              item-text="name"
              item-value="id"
              ref="refExpressAddbranchID"
              :rules="my_express_edit.rulesExpress"

              required
              persistent-hint
              return-object
              dense
              hide-details
              outlined
              single-line
              >
              <template slot="selection" slot-scope="data">
                <img
                  v-if="data.item.logo != null"
                  :src="data.item.logo"
                  class="mr-2"
                  style="width: 60px"
                />
                {{ data.item.name}}
              </template>
              <template slot="item" slot-scope="data">
                <img
                  v-if="data.item.logo != null"
                  :src="data.item.logo"
                  class="mr-2"
                  style="width: 60px"
                />
                {{ data.item.name}}
              </template>
      </v-select>
      <!-- <span v-if= "!my_express_edit.isUpdate"> asasdasd</span> -->
      <span v-else > <img :src="my_express_edit.Logo" width="100px" /> {{ my_express_edit.expressName }} </span>
      <span v-if="my_express_edit.expressValidateText !== null || my_express_edit.expressValidateText !== ''" style="color: red;">{{ my_express_edit.expressValidateText }}</span>
    </v-col>
  </v-row>
  <v-row v-show="![1].includes(my_express_edit.expressID) && my_express_edit.showHideAdd" >
    <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
      <span class="fn-14"> Account : </span>
    </v-col>
    <v-col cols="12" md="8" class="pt-1 pb-2" >
      <v-select 
                ref="refAccountValidate"
                :rules="my_express_edit.rulesAccount"
                v-model="my_express_edit.accountID"
                :items="my_express_edit.account_list"
                item-text="name"
                @change="(value) => (SetDataLogicticAccountForadd(value))"
                item-value="id"
                :disabled="my_express_edit.isOasysAccount "
                required
                outlined
                hide-details
                dense
      ></v-select>
      <span v-if="my_express_edit.accountID === null || my_express_edit.accountID === 0" style="color: red;">{{ my_express_edit.accountValidateText }}</span>
    </v-col>
    <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" v-if="!my_express_edit.isOasysAccount">
      <a @click="handleShowDialogAddAccount()" >
      <span class="fn-14"  > เพิ่ม acount ใหม่ </span>
    </a>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
      <span class="fn-14"> ต้นทุน COD : </span>
    </v-col>
    <v-col cols="12" md="8" class="pt-1 pb-2">
      <v-text-field
        :value="my_express_edit.costCod"
        @change="value => my_express_edit.costCod = value"
        :disabled="my_express_edit.expressID!=null && my_express_edit.expressID.id!=1 && (my_express_edit.isOasysAccount || my_express_edit.isCostPriceEditAble === false)"
        autocomplete="off"
        outlined
        dense hide-details
        @keypress="isNumberWithDot($event)"
      ></v-text-field> 
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
      <span class="fn-14"> ราคาขาย COD : </span>
    </v-col>
    <v-col cols="12" md="8" class="pt-1 pb-2">
      <v-text-field
        :value="my_express_edit.CodPrice"
        @change="value => my_express_edit.CodPrice = value"
        autocomplete="off"
        ref="refCodPersentAddbranchID"
        outlined
        :rules="my_express_edit.rulesCodPersent"
        :disabled="my_express_edit.expressID!=null && my_express_edit.expressID.id!=1 && !my_express_edit.isPriceEditAble"
        required
        dense hide-details
        @keypress="isNumberWithDot($event)"
      ></v-text-field>
      
      <span v-if="my_express_edit.CodPrice === null || my_express_edit.CodPrice === ''" style="color: red;">{{ my_express_edit.codPersentValidateText }}</span>
    </v-col>
  </v-row>

  <!-- <v-row>
    <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
      <span class="fn-14"> COD : </span>
    </v-col>
    <v-col cols="12" md="8" class="pt-3 pb-2">
      <v-radio-group
                  v-model="my_express_edit.isCod"
                  row
                >
              <v-radio
                label="เปิด"
                :value="true"
              ></v-radio>
              <v-radio
                label="ไม่เปิด"
                :value="false"
              ></v-radio>
            </v-radio-group>
    </v-col>
  </v-row> -->

  <!-- below code inter Express only  -->
        <v-row v-show="interExpressOption.showHide">
          <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
            <span class="fn-14"> ServiceCode : </span>
          </v-col>
          <v-col cols="12" md="8" class="pt-1 pb-2">
            <v-select 
                      v-model="interExpressOption.serviceCodeID"
                      :items="interExpressOption.serviceCode"
                      item-text="value"
                      item-value="id"
                      :rules="interExpressOption.rulesServiceCode"
                      outlined
                      hide-details
                      dense
                    ></v-select>
          </v-col>
        </v-row>
        <v-row v-show="interExpressOption.showHide">
          <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
            <span class="fn-14"> ServiceControlCode : </span>
          </v-col>
          <v-col cols="12" md="8" class="pt-1 pb-2">
            <v-select 
                      v-model="interExpressOption.serviceControlCodeID"
                      :items="interExpressOption.serviceControlCode"
                      item-text="value"
                      item-value="id"
                      :rules="interExpressOption.rulesServiceControlCode"
                      outlined
                      hide-details
                      dense
                    ></v-select>
          </v-col>
        </v-row>
        <v-row v-show="interExpressOption.showHide">
          <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
            <span class="fn-14"> TemperatureControlCode : </span>
          </v-col>
          <v-col cols="12" md="8" class="pt-1 pb-2">
            <v-select 
                      v-model="interExpressOption.temperatureControlCodeID"
                      :items="interExpressOption.temperatureControlCode"
                      item-text="value"
                      item-value="id"
                      :rules="interExpressOption.rulesTemperatureControlCode"
                      outlined
                      hide-details
                      dense
                    ></v-select>
          </v-col>
        </v-row>
        <v-row v-show="interExpressOption.showHide">
          <v-col cols="12" md="2" class="pt-4 pb-2 " align="left" >
            <span class="fn-14"> TemperatureTypeCode : </span>
          </v-col>
          <v-col cols="12" md="8" class="pt-1 pb-2">
            <v-select 
                      v-model="interExpressOption.temperatureTypeCodeID"
                      :items="interExpressOption.temperatureTypeCode"
                      item-text="value"
                      item-value="id"
                      :rules="interExpressOption.rulesTemperatureTypeCode"
                      outlined
                      hide-details
                      dense
                    ></v-select>
          </v-col>
        </v-row>
      </v-form>
      </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row row >
        <v-col cols="12" md="10" style="display: flex; flex-direction: row-reverse;">
                <v-checkbox  v-model="my_express_edit.isDefault"   hide-details  color="info" label= "ตั้งเป็นค่าเริ่มต้น" style="align-items: center"></v-checkbox>
          </v-col>
          <v-col class="" cols="12" md="2"  align="left">
          <v-btn  :elevation="1" color="primary" @click="handleUpdateBranchExpressClick()">
          <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
          <span class="fn-14"><b> บันทึก </b></span>
        </v-btn>
        </v-col>
        </v-row>
        </v-card-actions>
        

      </v-card>
    </v-dialog>

    <!-- ราคาขนส่ง -->
    <v-dialog v-model="my_express.dialog" width="80%" scrollable persistent >
      <v-card elevation="1" class="ma-0">
        <v-card-title >
            <span >ราคาค่าขนส่ง</span>  <img :src="my_express.expressLogoDialog" width="100px" />
          <v-spacer/>
          <v-btn icon @click="my_express.dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
        <v-form ref="formdata" lazy-validation>
        <v-data-table
                    :headers="deliveryPrice.headers"
                    :items="deliveryPrice"
                    :items-per-page="9999999999"
                    hide-default-footer
                    class="packhai-border-table"
                  >
                  <template v-for="(header, header_key) in deliveryPrice.headers" v-slot:[`header.${header.value}`]="{ header }">
                      <v-tooltip :key="header_key" v-slot:activator="{ on }">
                        <v-row v-if="!header.price">
                          <v-col cols="12">
                            {{ header.text }}
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols="12">
                            <v-row align="center" justify="center" class="PB-B">
                              <a @click="handleOPPProvinceDialog(true,header)"> {{ header.text }} <v-icon>mdi-eye-outline</v-icon></a>
                            </v-row>
                            <v-row>
                              <v-col cols="12" v-for="(i, i_key) in header.price" :key="i_key" :class="i.class">
                                <v-row align="center" justify="center">
                                  {{ i.text }}
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-tooltip>
                    </template>
                    <template v-slot:item="{item}">
                      <tr>
                        <td v-for="(col, columnIndex) in deliveryPrice.headers" :key="columnIndex" class="text-center">
                          <span v-if="col.text=='น้ำหนัก'" >{{ item[col.value] }}</span>
                          <span v-else-if="col.text=='ขนาด'" >{{ item.size }}</span>
                          <div>
                            <span v-if="col.price">
                              <span v-for="(data, itemIndex) in item.items" :key="itemIndex">
                                <v-row v-if="col.provinceGroupId==data.provinceGroupID">
                                  <v-col cols="12">
                                    <v-text-field
                                    :value="data.price"
                                      @change="value => data.price = value"
                                      :rules="[ v => !!v || 'กรุณาระบุ', ]"
                                      :disabled="!alldata.isPriceEditAble"
                                      autocomplete="off"
                                      outlined
                                      hide-details
                                      @keypress="isNumberWithDot($event)"
                                      class="center-input"
                                    ></v-text-field>
                                  </v-col>
                                  <!-- <v-col cols="6">
                                    <v-text-field
                                    :value="data.costPrice"
                                      @change="value => data.costPrice = value"
                                      autocomplete="off"
                                      outlined
                                      hide-details
                                      :disabled="!alldata.isCostPriceEditAble"
                                      @keypress="isNumberWithDot($event)"
                                      class="center-input"
                                    ></v-text-field>
                                  </v-col> -->
                                </v-row>
                              </span>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-divider />
        <v-card-actions>
            <v-row row >
            <v-col  cols="12"  align="right">
            <v-btn v-if="deliveryPrice.length!=0 &&  alldata.isPriceEditAble" :elevation="1" color="primary" @click="handleUpdateDeliveryPriceClick()">
            <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
            <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
            </v-col>
            </v-row>
            </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- เพิ่มกลุ่มจังหวัด -->
    <v-dialog v-model="my_express.dialogProvince" scrollable width="515" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="red darken-3" class="mr-3">mdi-group</v-icon>
          กลุ่มจังหวัด
          <v-spacer/>
          <v-btn icon @click="my_express.dialogProvince=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="oppdialog" lazy-validation>
            <v-row>
              <v-col cols="3">
                ชื่อกลุ่มจังหวัด:
              </v-col>
              <v-col cols="9">
                <v-text-field
                  :value="provinceGroup.description"
                  @change="value => provinceGroup.description = Trim_value(value)"
                  ref="provinceGroupdescription"
                  autocomplete="off"
                  outlined
                  dense hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="pt-2">
                เลือกจังหวัด: {{ total }}
              </v-col>
              <v-col cols="9" class="pt-2">
                <v-row class="select-province ma-0">
                  <v-col cols="12" id="scroll-target" class="overflow-y-auto pa-2 height-515">
                    <v-checkbox
                      v-for="i in province"
                      v-model="selectProvince"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id"
                      :disabled="i.disabled"
                      color="info"
                      class="mr-3"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ตั้งค่าการแพ็ค -->
    <v-dialog v-model="packsetting.packdialog" width="80%" scrollable persistent>
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <!-- <v-icon size="30" color="red" class="mr-1">mdi-package-variant-closed</v-icon> -->
          ตั้งค่าค่าบริการแพ็คสินค้า <img :src="my_express.expressLogoDialog" width="100px" />
          <v-spacer/>
          <v-btn icon @click="packsetting.packdialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>

        <v-card-text class="overflow-y-auto px-5">
          <v-form ref="DialogPackForm" lazy-validation>
                      <v-card-text class="pt-0">
                        <v-row row class="packhai-card-padding">
                          <v-radio-group
                            row
                            wrap
                            v-model="packsetting.packing"
                          >
                            <v-radio
                              v-for="i in packsetting.packing_List"
                              color="info"
                              v-bind:key="i['value']"
                              :label="i['label']"
                              :value="i['value']"
                            ></v-radio>
                          </v-radio-group>
                        </v-row>

                        <v-row
                          v-if=" [3,4].includes(packsetting.packing)"
                          row
                          wrap
                        >
                          <v-row
                            v-for="i in packsetting.priceAsClass"
                            :key="i.ID"
                            row
                            wrap
                            class="packhai-card-padding"
                          >
                            <v-col cols="2">
                              <v-text-field
                                :value="i.start"
                                @change="(value) => (i.start = value)"
                                autocomplete="off"
                                label="ชิ้นที่"
                                outlined
                                @keypress="isNumberWNoDot($event)"
                                disabled
                                hide-details
                                style="width: 200px"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                              <v-text-field
                                v-model="i.end"
                                label="ถึง"
                                :ref="'refEnd' + i.id"
                                outlined
                                autocomplete="off"
                                @keypress="isNumberWNoDot($event)"
                                @change="ChangeFollowPices( packsetting.priceAsClass, packsetting.priceAsClass.indexOf(i))"
                                hide-details
                                style="width: 200px"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                :value="i.price"
                                @change="(value) => (i.price = value)"
                                :rules="[(v) => CheckEmpty(v) || 'กรุณาระบุ']"
                                autocomplete="off"
                                label="ราคา"
                                outlined
                                hide-details
                                style="width: 200px"
                                @keypress="isNumberWithDot($event)"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-row>

                        <v-row row wrap>
                          <v-col cols="12">
                            <v-data-table
                              :headers="CheckPackingHeader(packsetting.packing )"
                              :items="packsetting.postboxlist"
                              :items-per-page="9999999999"
                              item-key="key"
                              hide-default-footer
                            >
                            <template v-slot:item.orderNo="{ item }">
                                <v-text-field
                                  v-model="item.orderNo"
                                  @change="
                                    SetOrderNo(
                                      packsetting.postboxlist,
                                      packsetting.postboxlist.indexOf(
                                        item
                                      )
                                    )
                                  "
                                  autocomplete="off"
                                  @keypress="isNumberWNoDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <template v-slot:item.name="{ item }">
                                <span
                                  v-if="
                                    (item.shopId == null && item.parentID != null) ||
                                    item.id != 0
                                  "
                                  >{{ item.name }}</span
                                >
                                <v-text-field
                                  v-else
                                  :value="item.name"
                                  @change="(value) => (item.name = value)"
                                  :rules="[(v) => CheckEmpty(v) || 'กรุณาระบุ']"
                                  autocomplete="off"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding pa-0"
                                />
                                
                              </template>
                              <template v-slot:item.cubic1="{ item }">
                                <span
                                  v-if="
                                    (item.shopId == null && item.parentID != null) ||
                                    item.id != 0
                                  "
                                  >{{ item.cubic1 }}</span
                                >
                                <v-text-field
                                  v-else
                                  :value="item.cubic1"
                                  @change="
                                    (value) => (item.cubic1 = value == '' ? null : value)
                                  "
                                  autocomplete="off"
                                  @keypress="isNumberWNoDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <template v-slot:item.cubic2="{ item }">
                                <span
                                  v-if="
                                    (item.shopId == null && item.parentID != null) ||
                                    item.id != 0
                                  "
                                  >{{ item.cubic2 }}</span
                                >
                                <v-text-field
                                  v-else
                                  :value="item.cubic2"
                                  @change="
                                    (value) => (item.cubic2 = value == '' ? null : value)
                                  "
                                  autocomplete="off"
                                  @keypress="isNumberWNoDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <template v-slot:item.cubic3="{ item }">
                                <span
                                  v-if="
                                    (item.shopId == null && item.parentID != null) ||
                                    item.id != 0
                                  "
                                  >{{ item.cubic3 }}</span
                                >
                                <v-text-field
                                  v-else
                                  :value="item.cubic3"
                                  @change="
                                    (value) => (item.cubic3 = value == '' ? null : value)
                                  "
                                  autocomplete="off"
                                  @keypress="isNumberWNoDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <template v-slot:item.boxCostPrice="{ item }">
                                <v-text-field
                                  :value="item.boxCostPrice"
                                  @change="
                                    (value) =>
                                      (item.boxCostPrice = value == '' ? null : value)
                                  "
                                  :disabled = "!packsetting.isCostPriceEditAble"
                                  autocomplete="off"
                                  @keypress="isNumberWithDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <template v-slot:item.boxSellPrice="{ item }">
                                <v-text-field
                                  :value="item.boxSellPrice"
                                  @change="
                                    (value) =>
                                      (item.boxSellPrice = value == '' ? null : value)
                                  "
                                  :disabled = "!packsetting.isPriceEditAble"
                                  autocomplete="off"
                                  @keypress="isNumberWithDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <template v-slot:item.packPrice="{ item }">
                                <v-text-field
                                  :value="item.packPrice"
                                  @change="
                                    (value) =>
                                      (item.packPrice = value == '' ? null : value)
                                  "
                                  :disabled = "!packsetting.isPriceEditAble"
                                  autocomplete="off"
                                  @keypress="isNumberWithDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <template v-slot:item.packAndBoxPrice="{ item }">
                                <v-text-field
                                  :value="item.packAndBoxPrice"
                                  @change="
                                    (value) =>
                                      (item.packAndBoxPrice = value == '' ? null : value)
                                  "
                                  :disabled = "!packsetting.isPriceEditAble"
                                  autocomplete="off"
                                  @keypress="isNumberWithDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <template v-slot:item.bubblePrice="{ item }">
                                <v-text-field
                                  :value="item.bubblePrice"
                                  @change="
                                    (value) =>
                                      (item.bubblePrice = value == '' ? null : value)
                                  "
                                  :disabled = "!packsetting.isPriceEditAble"
                                  autocomplete="off"
                                  @keypress="isNumberWithDot($event)"
                                  hide-details
                                  outlined
                                  class="packhai-card-padding center-input pa-0"
                                />
                              </template>
                              <!-- <template v-slot:item.delete="{ item }">
                                <a
                                  v-if="!packsetting.isPriceEditAble || !packsetting.isCostPriceEditAble">
                                  <v-icon size="16" color="red darken-3" disabled
                                    >fa-trash-alt</v-icon
                                  >
                                </a>
                                <a
                                  v-else
                                  @click="
                                    RemovePostBox(
                                      packsetting.postboxlist,
                                      packsetting.postboxlist.indexOf(
                                        item
                                      )
                                    )
                                  "
                                >
                                  <v-icon size="16" color="red darken-3"
                                    >fa-trash-alt</v-icon
                                  >
                                </a>
                              </template> -->
                          </v-data-table>
                          </v-col>
                        </v-row>
                        <v-row class="pt-5">
                          <v-col cols="12">
                            <v-btn
                              :elevation="1"
                              color="primary"
                              class="pa-2"
                              disabled
                            >
                              <v-icon size="14" class="mr-1" >fa-solid fa-plus</v-icon>
                              เพิ่ม
                            </v-btn>
                            <!-- <v-btn
                              v-else
                              :elevation="1"
                              color="primary"
                              class="pa-2"
                              @click="
                                AddPostBox(
                                  packsetting.postboxlist
                                )
                              "
                            >
                              <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                              เพิ่ม
                            </v-btn> -->
                          </v-col>
                        </v-row>
                    </v-card-text>
                  </v-form>
        </v-card-text>
        <v-divider />
        <!-- <v-card-actions >
            <v-row  row>
            <v-col  cols="12"  align="right" v-if = "!packsetting.isPriceEditAble || !packsetting.isCostPriceEditAble">
            <v-btn :elevation="1" color="primary" disabled>
            <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
            <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
            </v-col>
            <v-col  cols="12"  align="right" v-else>
            <v-btn :elevation="1" color="primary" @click="handleUpdatePackSettingClick()">
            <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
            <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
            </v-col>
            </v-row>
            </v-card-actions> -->
      </v-card>
    </v-dialog>

        <!-- ผู้ใช้ -->
    <v-dialog v-model="my_express_edit.accountDIalog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span>เพิ่ม account ขนส่ง </span>
          <v-spacer/>
          <v-btn icon @click="my_express_edit.accountDIalog=false "><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto">
          <v-form ref="DialogAddLogisticAccountForm" lazy-validation>

            <!-- <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3>  Shippop? : </h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-radio-group
                  v-model="dialog_data.isShippop"
                  row
                >
                  <v-radio
                    label="No"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    label="Yes"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3> การจ่ายเงิน : </h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-radio-group
                  v-model="dialog_data.isOMS"
                  row
                  @change="change_title()"
                >
                  <v-radio
                    label="จ่ายเงิน PACKHAI HQ"
                    :value="false"
                  ></v-radio>
                  <v-radio
                    label="จ่ายเงินกับขนส่งโดยตรง"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row> -->
            <v-row row wrap class="py-0">
              <v-col cols="12" md="3">
                <h3> ขนส่ง : </h3>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-select
                  v-model="dialog_data.expressCompanyID"
                  ref="expressCompanyID"
                  :items="dialog_data.expressCompanyList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  disabled="true"
                  dense
                  hide-details
                  outlined
                  single-line
                  @change="change_title()"
                >
                <template slot="selection" slot-scope="data">
                <img
                  v-if="data.item.logoPicSmall != null"
                  :src="data.item.logoPicSmall"
                  class="mr-2"
                  style="width: 60px"

                />
                {{ data.item.name}}
              </template>
              <template slot="item" slot-scope="data">
                <img
                  v-if="data.item.logoPicSmall != null"
                  :src="data.item.logoPicSmall"
                  class="mr-2"
                  style="width: 60px"
                />
                {{ data.item.name}}
              </template>
              </v-select>
              </v-col>
            </v-row>

            <v-row row wrap class=" py-0">
              <v-col cols="12" md="3" class="pb-2"  v-if="LoginEmail_or_ViP_ID_IsShow">
                <h4 v-if="vip_id_title == null"> ViP_ID :</h4>
                <h4 v-else> {{ vip_id_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2"  v-if="LoginEmail_or_ViP_ID_IsShow">
                <v-text-field
                  v-model="dialog_data.viP_ID"
                  autocomplete="off"
                  ref="viP_ID"
                  outlined
                  dense hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pb-2" v-if="Key_or_ViP_Password_IsShow">
                <h4 v-if="vip_password_title  == null"> ViP_Password :</h4>
                <h4 v-else> {{ vip_password_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2"  v-if="Key_or_ViP_Password_IsShow">
                <v-text-field
                  v-model="dialog_data.viP_Password"
                  autocomplete="off"
                  ref="viP_Password"
                  outlined
                  dense hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row row wrap class="py-0" v-if="!dialog_data.isShippop && CustomerID_IsShow">
              <v-col cols="12" md="3">
                <h4 v-if="customer_id_title  == null"> CustomerID :</h4>
                <h4 v-else> {{ customer_id_title }} :</h4>
              </v-col>
              <v-col cols="12" md="9" class="pb-2">
                <v-text-field
                  v-model="dialog_data.customerID"
                  autocomplete="off"
                  ref="refCustomerID"
                  outlined
                  dense hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row row wrap class="py-0">
              <v-col cols="12" md="12" align="right">
                <v-btn dark color="primary" class="mr-1" @click="AddEditAccount()">
                  <v-icon left>mdi-content-save</v-icon>
                  บันทึก
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import Loading from '@/website/components/Loading'
  import { header_token } from '@/website/backend/token'
  import {  generalService_dotnet,logistic_Service  } from '@/website/global'
  import { get_languages , FormatInvoice, formatMoney, isNumberWNoDot, set_format_date_time, format_price, format_vat_type,isNumberWithDot,Trim_value,Trim_rules, } from '@/website/global_function'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'


  export default {
    components: {
      Loading
    },
    data: vm => ({
      // page
      page_loading: true,
      loading: false,
      isFirstLoad: true,
      use_language: null,
      set_language: null,

      my_express: {
        headers: [
          { text: 'ลำดับ', align: 'left', sortable: false, value: 'no', width : "50px"},
          { text: 'แก้ไข', align: 'left', sortable: false, value: 'Edit',width : "50px"},
          { text: 'ตั้งค่าการแพ็ค', align: 'left', sortable: false, value: 'viewDialogPackSetting', width : "140px"},
          { text: 'ดูค่าขนส่ง', align: 'left', sortable: false, value: 'viewDialogMyexpress', width : "140px"},
          { text: 'ขนส่ง', align: 'left', sortable: false, value: 'photoLink' , width : "120px"},
          { text: 'Account', align: 'left', sortable: false, value: 'Name', width : "220px"},
          { text: 'ชื่อขนส่ง', align: 'left', sortable: false, value: 'shopExpressName' , width : "120px"},
          { text: 'COD', align: 'left', sortable: false, value: 'COD',width : "50px"},
          { text: 'ราคาขาย COD', align: 'left', sortable: false, value: 'codpercent', width : "100px"},
          { text: 'ต้นทุน COD', align: 'left', sortable: false, value: 'codcost', width : "50px"},
          { text: 'สถานะ', align: 'left', sortable: false, value: 'statusMessage', width : "170px"},
          { text: 'เปิดใช้งาน', align: 'left', sortable: false, value: 'isActive', width : "80px"},
          { text: 'ลบ', align: 'left', sortable: false, value: 'Delete',width : "50px"},
        ],
        dataset: [],
        dialog:false,
        expressLogoDialog:"",
        filterDialogShow:"",
        ShopExpressId:null,
        dialogProvince:false,
        isUpdate: false,
      },
      my_express_edit:{
        dialog:false,
        isUpdate:false,
        Logo:null,
        expressID:null,
        expressName:null,
        accountID:null,
        account_list:[],
        branchExpressID:null,
        branchLogisticAccountID:null,
        costCod:null,
        CodPrice:null,
        isDefault:false,
        isCod:false,
        isOasysAccount:null,
        dataAccount_list:[],
        isCostPriceEditAble:null,
        isPriceEditAble:false,
        accountDIalog:false,
        showHideAdd:false,
        rulesExpress:[],
        rulesCodPersent:[],
        rulesAccount:[],
        accountValidateText:'',
        expressValidateText:'',
        codPersentValidateText:''
      },
      interExpressOption : {
        showHide:false,
        serviceCodeID:null,
        rulesServiceCode:[],
        rulesServiceControlCode:[],
        rulesTemperatureControlCode:[],
        rulesTemperatureTypeCode:[],
        serviceCodeValidateText:'',
        serviceControlCodeValidateText:'',
        temperatureControlCodeValidateText:'',
        temperatureTypeCodeValidateText:'',
        serviceCode:[
            {id: 'Express', value: "ส่งด่วน" },
            {id: 'Same Day', value: "ส่งด่วนภายในวัน"},
            {id: 'Standard', value: "ส่งธรรมดา"},
        ],
        serviceControlCodeID:null,
        serviceControlCode:[
            {id: 'SV001', value: "บริการขนส่งสินค้าทั่วไป"},
            {id: 'SV002', value: "บริการขนส่งผัก-ผลไม้"},
            {id: 'SV003', value: "บริการขนส่งกล่องโฟม"},
            {id: 'SV004', value: "บริการแพ็คพร้อมส่ง"},
            {id: 'SV005', value: "บริการขนส่งกล่องกระดาษเย็น"},
        ],
        temperatureControlCodeID:null,
        temperatureControlCode:[
            {id: 'General', value: "สินค้าทั่วไป"},
            {id: 'Temperature_Control', value: "สินค้าควบคุมอุณหภูมิ"},
        ],
        temperatureTypeCodeID:null,
        temperatureTypeCode:[
            {id: 'Chilled', value: "สินค้าควบคุมอุณหภูมิที่ 2-8 องศา"},
            {id: 'Frozen', value: "สินค้าควบคุมอุณหภูมิที่ต่ำกว่า -15 องศา"},
            {id: 'General', value: "ขนส่งสินค้าทั่วไป"},
            {id: 'Air', value: "สินค้าควบคุมอุณหภูมิที่ 15 - 25 องศา"},
        ],
      },
      alldata:[],
      deliveryPrice:[],
      // province
      total: 0,
      provinceIdList: [],
      selectProvince: [],
      province: [],
      provinceTemp: [],
      provinceGroupItem: [],
      provinceGroup:[],

      // packsetting
      packsetting:{
        isCostPriceEditAble:false,
        isPriceEditAble:false,
        packdialog:false,
        postboxlist:[],
        packing:3,
        packing_List:[
            { label: "ตามจำนวนชิ้น", value: 3 },
            { label: "ตาม SKU", value: 4 },
            { label: "ตามขนาดกล่อง", value: 2 },
            { label: "คงที่", value: 1 },
        ],
        priceAsClass: [
            { id: 0, start: 1, end: 1, price: 0 },
            { id: 1, start: 2, end: null, price: 3 },
          ],
        removeItem:[]
      },

        //dialog เพิ่ม account 
        dialog_data : {
        id: 0,
        expressCompanyID: null,
        expressCompanyList:null,
        shopID: null,
        shopList:null,
        isShippop: false,
        viP_ID: null,
        viP_Password: null,
        customerID: null,
        isOMS: true,
        isNeedCollectDeliveryPrice: false,
        isPickup: false
      },
      LoginEmail_or_ViP_ID_IsShow : true,
      Key_or_ViP_Password_IsShow : true,
      CustomerID_IsShow : true ,
      vip_id_title : null,
      vip_password_title : null,
      customer_id_title : null,

    }),
    watch: {

      "my_express_edit.accountDIalog": {
      handler(value) {
        if (!value) {
          if (this.my_express_edit.isUpdate) {
            this.my_express_edit.dialog = true;
          } else {
            this.handleOpenDialogEditExpress(null,false)
          }
        }
      },
      deep: true,
    },
    },
    async created() {


      await this.get_shop_express()
      this.page_loading = false
      this.checklog()
       //เก็บภาษามาไว้ใน use_language
      this.use_language = await this.get_languages(localStorage.getItem("set_language"));
    },
    methods: {
      async get_shop_express () {
        let response = await axios.get(logistic_Service+'ShopExpress/get-shop-express-list?ShopID='+parseInt(localStorage.getItem('shop_id')),
         { headers: header_token })
        this.loading = false
        if (response.data.status === 'success') {
          this.my_express.dataset = response.data.shopExpressList
          if(this.my_express.dataset[0].branchId == 2 && this.isFirstLoad){
            this.my_express.headers.splice(2,1);
            this.isFirstLoad=false;
          }
        }
      },
      async get_shop_express_delivery_price(expressBId) {
        let response = await axios.get(logistic_Service+'ShopExpress/get-shop-express-delivery-price?ShopExpressID='+expressBId, 
         { headers: header_token })
         this.loading = true
        if (response.data.status === 'success') {
          this.loading = false
          this.my_express.ShopExpressId = expressBId
          this.alldata = response.data
          this.deliveryPrice = response.data.deliveryPrices
          this.provinceGroup = response.data.provinceGroupList
          this.provinceGroupItem = response.data.provinceGroupItemList
          await this.SetDeliveryPrice()
        }
      },
      async get_shop_express_postbox (expressBId) {
        this.my_express.ShopExpressId = expressBId
        let response = await axios.get(logistic_Service+'ShopExpress/get-shop-express-postbox?ShopExpressID='+expressBId, 
         { headers: header_token })
        this.loading = false
        if (response.data.status === 'success') {
          this.packsetting.postboxlist = response.data.postboxList
          this.packsetting.packing = response.data.packingChargedType
          this.packsetting.isCostPriceEditAble = response.data.isCostPriceEditAble
          this.packsetting.isPriceEditAble = response.data.isPriceEditAble
          this.packsetting.priceAsClass = response.data.packingStep  === null || response.data.packingStep.length === 0  ? [
          { id: 0, start: 1, end: "1", price: 0 ,no:1 },
          { id: 1, start: 2, end: "", price: 3 , no:2 },
        ] : response.data.packingStep
        }
      },
      async get_logistic_account_edit (expressBId) {
        let response = await axios.get(logistic_Service+'ShopExpress/get-shop-express-logistic-account-for-edit?ShopExpressID=' +expressBId, 
         { headers: header_token })

        if (response.data.status === 'success') {
              this.my_express_edit.account_list= []
              this.my_express_edit.accountID= null
              for(var x in response.data.accountList){
                this.my_express_edit.account_list.push({
                  id: response.data.accountList[x].id,
                  name: response.data.accountList[x].shortName,
                })
            }
        }
      },
      async get_shop_express_for_add () {
        let response = await axios.get(logistic_Service+'ShopExpress/get-shop-express-for-add?ShopID='+parseInt(localStorage.getItem('shop_id')), 
         { headers: header_token })
        this.loading = false
        if (response.data.status === 'success') {
          return response.data.expressList
        }
      },
      async get_shop_express_list () {
        let response =  await axios.post(logistic_Service + "Logistic/get-express-list",
            {
              branchId: localStorage.getItem("Branch_BranchID"),
            },
            { headers: header_token }
          )
        this.loading = false
        if(response.status === 200 ){
          return response.data
        }

      },
      async handleUpdateIsActive (item) {
        Swal.fire({
        position: "top",
        type: "warning",
        text: "ยืนยันการเลือก",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
        let response = await axios.post(logistic_Service+'ShopExpress/update-active-shop-express', {
          "ShopExpressID":item.id,
          "isActive":!item.isActive
        },
        { headers: header_token})
        this.loading = false
        if (response.data.status === 'success') {
          await this.get_shop_express()
        }
      }
      });
      },
      async handleDeleteMyExpreesClick (item) {
        Swal.fire({
     
        type: "question",
        title: "คุณแน่ใจว่าต้องการลบ?",
        text : "ต้องการลบขนส่ง \""+ item.expressCompanyName+"\"",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
        let response = await axios.get(logistic_Service+'ShopExpress/delete-shop-express?ShopExpressID='+item.id, 
         { headers: header_token })
        this.loading = false
        if (response.data.status === 'success') {
          await this.get_shop_express()
        }
      }
      });
      },
      async handleOpenDialogExpress (item,type) {
        this.my_express.expressLogoDialog = item.expressCompanyLogo
        if(type === 1)
        {
          this.my_express.dialog = true
          this.deliveryPrice = []
          await this.get_shop_express_delivery_price(item.id)
        }
        else if(type === 2)
        {
          this.packsetting.packdialog = true
          await this.get_shop_express_postbox(item.id)
        }
      },
      async handleUpdateDeliveryPriceClick(){
        if (this.$refs.formdata.validate()) {
          this.loading = true
            for (var o in this.deliveryPrice){
              for (var a in this.deliveryPrice[o].items){
                this.deliveryPrice[o].items[a].costPrice = this.deliveryPrice[o].items[a].costPrice === "" ? null : this.deliveryPrice[o].items[a].costPrice
              }
            }
          let response = await axios.post(logistic_Service+'ShopExpress/update-shop-express-delivery-price', {
            "shopExpressID":   this.my_express.ShopExpressId,
            "deliveryPrices":this.deliveryPrice,
          }, {headers: header_token})
          this.loading = false
          if (response.data.status  === "success") {
            this.AlertSuccess()
          } else {
            this.AlertError()
          }
        } 
      },
      async handleOPPProvinceDialog(isUpdate,item){
        // this.loading = true
        // this.rulesDescription()

        if (this.province.length===0){
          let province = await axios.post(logistic_Service+'Logistic/get-province', {
          }, {headers: header_token})
          if (province.status ===200) {
            var data = []
            for (var i in province.data){
              var dataItem = province.data[i]
              dataItem['disabled'] = false
              data.push(dataItem)
            }
            this.province = data
            this.provinceTemp = data
          }
        } else {
          this.province = this.provinceTemp
        }

        this.my_express.isUpdate = false
        this.total = 0
        this.selectProvince = []
        this.provinceGroup.id = 0
        this.provinceGroup.description = null
        this.provinceGroup.expressCompanyId = item.id

        for (var j in this.provinceGroupItem){
          this.selectProvince.push(this.provinceGroupItem[j].provinceId)
        }

        for (var k in this.province){
          if (this.selectProvince.includes(this.province[k].id)){
            this.province[k].disabled = true
          }
        }

        if (isUpdate){
          this.my_express.isUpdate = true
          this.provinceGroup.id = item.provinceGroupId
          this.provinceGroup.description = item.text
          var selectProvince = []
          for (var o in this.provinceGroupItem){
            if (this.provinceGroupItem[o].provinceGroupId===item.provinceGroupId){
              selectProvince.push(this.provinceGroupItem[o].provinceId)
            }
          }

          for (var l in this.province){
            if (selectProvince.includes(this.province[l].id)){
              this.province[l].disabled = false
            }
          }

          this.total = selectProvince.length
        }
        // this.loading = false
        this.my_express.dialogProvince = true
      },
      SetDeliveryPrice(){
          if (this.deliveryPrice.length!=0){
            this.deliveryPrice.headers = [
              { align: 'center', text: 'น้ำหนัก', value: 'weight', sortable: false },
            ]
              this.deliveryPrice.headers.push({
                'align': 'center',
                'text': 'ขนาด',
                'sortable': false,
                'value': 'ขนาด'
              })
            
            var columns = this.deliveryPrice[0].items
            for (var dc in columns){
              this.deliveryPrice['headers'].push({
                'align': 'center',
                'text': this.provinceGroup.filter(x => x.id === columns[dc]["provinceGroupID"]).map(i => i.description)[0],
                'provinceGroupId': columns[dc]["provinceGroupID"],
                'class': 'my-header-style',
                'sortable': false,
                'value': this.provinceGroup.filter(x => x.id === columns[dc]["provinceGroupID"]).map(i => i.description)[0],
                'price': [
                  { text: 'ค่าขนส่ง', value: 'price',class: 'my-header-child-style'},
                  // { text: 'ต้นทุนค่าขนส่ง', value: 'costPrice' ,class: 'my-header-child-style' },
                ]
              })
            }
          
        }
      },
      async handleUpdatePackSettingClick(){
          this.loading = true
          let response = await axios.post(logistic_Service+'ShopExpress/update-shop-express-postbox', {
            "shopExpressID":   this.my_express.ShopExpressId,
            "packingChargedType":this.packsetting.packing,
            "postboxList":this.packsetting.postboxlist,
            "deletedPostboxID":this.packsetting.removeItem,
          }, {headers: header_token})
          this.loading = false
          if (response.data.status  === "success") {
            await  this.AlertSuccess()
            setTimeout(() => {
              this.packsetting.packdialog = false
                }, 1500);
          } else {
            this.AlertError()
          }
      },
      async handleOpenDialogEditExpress (item,isUpdate) {
       this.my_express_edit.dialog = true
       this.my_express_edit.isUpdate = isUpdate
       this.my_express_edit.isDefault = false;
       await this.ClearRules()
       if(isUpdate)
       {
        this.my_express_edit.isDefault = item.isDefault
          this.my_express_edit.expressID = {"id":  item.expressCompanyId,"name": item.expressCompanyName,"logo":null};
          this.my_express_edit.expressName = item.expressCompanyName
          this.my_express_edit.accountName = item.logisticAccountShortName
          this.my_express_edit.costCod = await item.codcost,  //ใส่await ไว้ตอนเพิ่มแล้วมากดแก้ไข ค่าเปลี่ยนช้าทำให้แสดงเป็นnull
          this.my_express_edit.CodPrice = await item.codpercent
          this.my_express_edit.Logo = item.expressCompanyLogo
          this.my_express_edit.isOasysAccount = item.isOasysAccount
          this.my_express.ShopExpressId = item.id
          this.my_express_edit.showHideAdd = item.expressCompanyId !== 1 ? true :false
          this.my_express_edit.isPriceEditAble = item.isPriceEditAble
          this.my_express_edit.isCostPriceEditAble = item.isCostPriceEditAble
          this.my_express_edit.branchExpressID = item.branchExpressID
          this.my_express_edit.branchLogisticAccountID = item.branchLogisticAccountID
          this.my_express_edit.isCod = item.isAllowCod

          

          // interexpress only
          this.interExpressOption.showHide = item.expressCompanyId === 18 ? true : false
          this.interExpressOption.serviceCodeID = item.servicecode
          this.interExpressOption.serviceControlCodeID = item.serviceControlCode
          this.interExpressOption.temperatureControlCodeID = item.temperatureControlCode
          this.interExpressOption.temperatureTypeCodeID = item.temperatureTypeCode

          await this.get_logistic_account_edit(item.id)
          this.my_express_edit.accountID = item.shopLogisticAccountID
       }
       else{
        // let expressResponse =  await this.get_branch_express_list()
        let for_add =  await this.get_shop_express_for_add()
          this.my_express_edit.BranchExpressName = null 
          this.my_express_edit.accountName =  null
          this.my_express_edit.costCod = null,
          this.my_express_edit.CodPrice = null
          this.my_express_edit.Logo = null
          this.my_express_edit.account_list= []
          this.my_express_edit.accountID= null
          this.my_express_edit.isCod = false
          this.my_express_edit.isOasysAccount = null
          this.my_express.ShopExpressId = null
          this.interExpressOption.serviceCodeID = null
          this.interExpressOption.serviceControlCodeID = null
          this.interExpressOption.temperatureControlCodeID = null
          this.interExpressOption.temperatureTypeCodeID = null
          this.interExpressOption.showHide = false
          this.my_express_edit.isPriceEditAble = false
          this.my_express_edit.isCostPriceEditAble = false
          this.my_express_edit.branchExpressID = null

          //ยัดexpress เข้า dropdown
          this.my_express_edit.expressID = { id: 0, name: "--ทั้งหมด--",logo:null }
          this.my_express_edit.expressName = [{ id: 0, name: "--ทั้งหมด--",logo:null }]
          for(var x in for_add){
                this.my_express_edit.expressName.push({
                  id: for_add[x].expressCompanyID,
                  name: for_add[x].expressCompanyName,
                  logo:for_add[x].expressCompanyLogo
                })
            }
          this.$watch(() => this.my_express_edit.expressID.id, (value) => { 
            var data = for_add.filter(x => x.expressCompanyID === value)
            this.my_express_edit.account_list= []
            this.my_express_edit.accountID= null
            this.my_express_edit.costCod = null,
            this.my_express_edit.CodPrice = null
            this.my_express_edit.expressValidateText = value === 0 ?  this.my_express_edit.expressValidateText : ''
            this.my_express_edit.showHideAdd = value ===0 || value === 1 ? false : true
            //inter express only
            this.interExpressOption.showHide = value === 18 ? true : false
            /////

            this.my_express_edit.BranchExpressName = value === 0 ? null : 
            this.my_express_edit.isUpdate ? item.branchExpressName : 
            this.my_express_edit.expressID['name']  
            
            for(var x in data[0].accountList){
                this.my_express_edit.account_list.push({
                  id: data[0].accountList[x].id,
                  name: data[0].accountList[x].shortName,
                })
                
            }
            this.my_express_edit.dataAccount_list = data[0].accountList
          });
       }
      },
      async handleUpdateBranchExpressClick(){
      if(this.my_express_edit.expressID.id === 0 || this.my_express_edit.expressID.id === null)
      {
        
        this.my_express_edit.rulesExpress = [(v) => v && v.id !== 0 || "กรุณาระบุ"];
        this.$refs.refExpressAddbranchID.focus()
        this.my_express_edit.expressValidateText = 'กรุณาเลือกขนส่ง'
        // this.$refs.formAddExpress.validate()
        return
      }
      else {
        if(this.my_express_edit.accountID === 0 || this.my_express_edit.accountID === null )
        {
          if(this.my_express_edit.expressID.id !== 1 && this.my_express_edit.expressID !== 1 && !this.my_express_edit.isOasysAccount){
          this.my_express_edit.rulesAccount = [(v) => v && v.id !== 0 || "กรุณาระบุ"];
          this.$refs.refAccountValidate.focus()
          this.my_express_edit.accountValidateText = 'กรุณาเลือก account'
          this.$refs.formAddExpress.validate()
          return
          }
          
        }
        if(this.my_express_edit.CodPrice === null || this.my_express_edit.CodPrice === '') {
          this.my_express_edit.rulesCodPersent = [(v) => !!v || "กรุณาระบุ"];
          this.$refs.refCodPersentAddbranchID.focus()
          this.my_express_edit.codPersentValidateText = 'กรุณาระบุราคาขาย COD'
          // this.$refs.formAddExpress.validate()
          return
        }
        
        if(this.my_express_edit.expressID === 18 || this.my_express_edit.expressID.id === 18)
        {
          await this.SetRulesInterExpress()
          if(this.interExpressOption.serviceCodeID === null || this.interExpressOption.serviceCodeID === ''){
            this.$refs.formAddExpress.validate()
            return
          }
          else if(this.interExpressOption.serviceControlCodeID === null || this.interExpressOption.serviceControlCodeID === ''){
            this.$refs.formAddExpress.validate()
            return
          }
          else if(this.interExpressOption.temperatureControlCodeID === null || this.interExpressOption.temperatureControlCodeID === ''){
            this.$refs.formAddExpress.validate()
            return
          }
          else if(this.interExpressOption.temperatureTypeCodeID === null || this.interExpressOption.temperatureTypeCodeID === ''){
            this.$refs.formAddExpress.validate()
            return
          }
        }
      }
        this.loading = true
        let response =  await axios.post(logistic_Service + "ShopExpress/update-shop-express",
            {
              id:this.my_express_edit.isUpdate ? this.my_express.ShopExpressId : 0,
              shopID:parseInt(localStorage.getItem('shop_id')),
              // isAllowCOD:this.my_express_edit.isCod,
              branchID:localStorage.getItem("branch_id"),
              branchExpressID:this.my_express_edit.branchExpressID,
              expressCompanyID:this.my_express_edit.expressID.id,
              shopLogisticAccountID:this.my_express_edit.accountID,
              branchLogisticAccountID:this.my_express_edit.branchLogisticAccountID,
              codPercent:this.my_express_edit.CodPrice,
              codCost:this.my_express_edit.costCod,
              isDefault:this.my_express_edit.isDefault,
            },
            { headers: header_token }
          )
          if(response.data.status === "success")
          {
            this.loading = false
            this.my_express_edit.dialog = false
            await this.get_shop_express()
            this.AlertSuccess()
          }
          else
          {
            this.loading = false
            this.AlertError()
          }
        
      },
      async handleShowDialogAddAccount() 
      {
        this.dialog_data = {
        id: 0,
        expressCompanyID: null,
        expressCompanyList:null,
        isShippop: false,
        viP_ID: null,
        viP_Password: null,
        customerID: null,
        isOMS: true,
        isNeedCollectDeliveryPrice: false,
        isPickup: false
      },
          this.my_express_edit.accountDIalog = true
          this.my_express_edit.dialog = false
          const responseExpress =  await this.get_shop_express_list()
          
          //ยัดexpress เข้า dropdown
          this.dialog_data.expressCompanyID = { id: 0, name: "--ทั้งหมด--" }
          this.dialog_data.expressCompanyList = [{ id: 0, name: "--ทั้งหมด--" }]

          this.dialog_data.expressCompanyList = responseExpress.filter(
                  (x) =>  ![1].includes(x.id)
                );
                this.dialog_data.expressCompanyList.splice(0, 0, {
                  id: 0,
                  name: "--ทั้งหมด--",
                });

          this.dialog_data.expressCompanyID =   this.my_express_edit.expressID 
          this.vip_id_title = null
          this.vip_password_title = null
          this.customer_id_title = null

          this.LoginEmail_or_ViP_ID_IsShow = true
          this.Key_or_ViP_Password_IsShow = true
          this.CustomerID_IsShow = true

          //เก็บไว้ก่อนเผื่อใช้
          // this.dialog_data = item
          this.change_title()
        
      },
      SetDataLogicticAccountForadd(id){
      if(!this.my_express_edit.isUpdate)
      {
        var dataAccount_list = this.my_express_edit.dataAccount_list.filter(x => x.id === id)
          this.my_express_edit.costCod = dataAccount_list[0].codCost
          this.my_express_edit.CodPrice = dataAccount_list[0].codPercent
          this.my_express_edit.isCostPriceEditAble = dataAccount_list[0].isCostPriceEditAble
          this.my_express_edit.isPriceEditAble = dataAccount_list[0].isPriceEditAble
      }
      },
      async AddEditAccount() {
      if (this.dialog_data.expressCompanyID.id == 0) {
        this.$refs.expressCompanyID.focus();
        return;
      } else if (
        this.LoginEmail_or_ViP_ID_IsShow == true &&
        (this.dialog_data.viP_ID == null || this.dialog_data.viP_ID == "")
      ) {
        this.$refs.viP_ID.focus();
        return;
      } else if (
        this.Key_or_ViP_Password_IsShow == true &&
        (this.dialog_data.viP_Password == null || this.dialog_data.viP_Password == "")
      ) {
        this.$refs.viP_Password.focus();
        return;
      } else if (
        this.CustomerID_IsShow == true &&
        (this.dialog_data.customerID == null || this.dialog_data.customerID == "")
      ) {
        this.$refs.refCustomerID.focus();
        return;
      }

      var param = this.dialog_data;
      this.loading = true;
      let response = await axios.post(
        logistic_Service + "Logistic/add-edit-logistic-account",
        {
          id: param.id,
          expressCompanyID: param.expressCompanyID.id,
          branchID: localStorage.getItem("Branch_BranchID"),
          shopID: parseInt(localStorage.getItem('shop_id')),
          isShippop: param.isShippop,
          viP_ID: param.viP_ID,
          viP_Password: param.viP_Password,
          customerID: param.customerID,
          isOMS: param.isOMS,
          isNeedCollectDeliveryPrice: param.isNeedCollectDeliveryPrice,
          isPickup: param.isPickup,
        },
        { headers: header_token }
      );
      if (response.data.status == "success") {
        if (response.data.error_description != null) {
          Swal.fire({
            icon: "warning",
            title: response.data.error_description,
            // text: response.data.error_description,
          });
          this.AlertSuccess();
          this.loading = false;
          return;
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "add-edit-logistic-account เกิดข้อผิดพลาด !!",
          text: response.data.error_description,
        });
        this.loading = false;
        return;
      }
      if (this.my_express_edit.isUpdate) {
        this.my_express_edit.dialog = true;
        this.my_express_edit.accountDIalog = false;
        await this.get_logistic_account_edit(this.my_express.BranchExpressId);
      } else {
        this.my_express_edit.accountDIalog = false;
      }
      this.loading = false;
    },
      change_title (){
        if(this.dialog_data.expressCompanyID !== 0 || this.dialog_data.expressCompanyID.id !== 0){
          if(this.dialog_data.expressCompanyID === 1 || this.dialog_data.expressCompanyID.id === 1){

              this.vip_id_title = "PartnerID"
              this.vip_password_title = "PartnerKey"
              this.customer_id_title = null

              this.LoginEmail_or_ViP_ID_IsShow = true
              this.Key_or_ViP_Password_IsShow = true
              this.CustomerID_IsShow = false
            
          }else if (this.dialog_data.expressCompanyID === 2 || this.dialog_data.expressCompanyID.id === 2) {

              this.vip_id_title = "เบอร์โทร EasyShip"
              this.vip_password_title = null
              this.customer_id_title = null

              this.LoginEmail_or_ViP_ID_IsShow = true
              this.Key_or_ViP_Password_IsShow = false
              this.CustomerID_IsShow = false
            
          }else if (this.dialog_data.expressCompanyID === 3 || this.dialog_data.expressCompanyID.id === 3) {

              this.vip_id_title = null
              this.vip_password_title = null
              this.customer_id_title = "เบอร์โทร Wallet@Post"

              this.LoginEmail_or_ViP_ID_IsShow = false
              this.Key_or_ViP_Password_IsShow = false
              this.CustomerID_IsShow = true
            
          }else if (this.dialog_data.expressCompanyID === 7 || this.dialog_data.expressCompanyID.id === 7) {

              this.vip_id_title = "MerchantID"
              this.vip_password_title = "Merchant Token"
              this.customer_id_title = null

              this.LoginEmail_or_ViP_ID_IsShow = true
              this.Key_or_ViP_Password_IsShow = true
              this.CustomerID_IsShow = false
            
          }else if (this.dialog_data.expressCompanyID === 11 || this.dialog_data.expressCompanyID.id === 11) {

              this.vip_id_title = "ShopID"
              this.vip_password_title = "ShopName"
              this.customer_id_title = "VIP ID"

              this.LoginEmail_or_ViP_ID_IsShow = true
              this.Key_or_ViP_Password_IsShow = true
              this.CustomerID_IsShow = true
            
          }else if (this.dialog_data.expressCompanyID === 12 || this.dialog_data.expressCompanyID.id === 12) {

              this.vip_id_title = "Seller ID"
              this.vip_password_title = "Seller Company Name"
              this.customer_id_title = null

              this.LoginEmail_or_ViP_ID_IsShow = true
              this.Key_or_ViP_Password_IsShow = true
              this.CustomerID_IsShow = false
            
          }else if (this.dialog_data.expressCompanyID === 13 || this.dialog_data.expressCompanyID.id === 13) {

              this.vip_id_title = "SoldToAccountID"
              this.vip_password_title = "PickupAccountID"
              this.customer_id_title = null

              this.LoginEmail_or_ViP_ID_IsShow = true
              this.Key_or_ViP_Password_IsShow = true
              this.CustomerID_IsShow = false
            
          }else if (this.dialog_data.expressCompanyID === 17 || this.dialog_data.expressCompanyID.id === 17) {

              this.vip_id_title = "User ID"
              this.vip_password_title = "User Secret"
              this.customer_id_title = null

              this.LoginEmail_or_ViP_ID_IsShow = true
              this.Key_or_ViP_Password_IsShow = true
              this.CustomerID_IsShow = false

            }else if ([18,19,20,21,22,23].includes(this.dialog_data.expressCompanyID) || [18,19,20,21,22,23].includes(this.dialog_data.expressCompanyID.id)) {

              this.vip_id_title = "Public_Key "
              this.vip_password_title = "Secret_Key"
              this.customer_id_title = null

              this.LoginEmail_or_ViP_ID_IsShow = true
              this.Key_or_ViP_Password_IsShow = true
              this.CustomerID_IsShow = false

        }else {
          this.vip_id_title = null
          this.vip_password_title = null
          this.customer_id_title = null

          this.LoginEmail_or_ViP_ID_IsShow = true
          this.Key_or_ViP_Password_IsShow = true
          this.CustomerID_IsShow = true

        }
        if(this.dialog_data.id == 0){
          this.dialog_data.viP_ID = null
          this.dialog_data.viP_Password = null
          this.dialog_data.customerID = null
        }

      }
      },
      ChangeFollowPices(pName, index) {
      if (pName[index]["end"] === "") {
        pName.splice(index + 1);
        return;
      } else if (pName.length - 1 === index) {
        if (parseInt(pName[index]["end"]) < parseInt(pName[index]["start"])) {
          pName[index]["end"] = "";
          return;
        }
        pName.push({
          id: parseInt(pName[index]["id"]) + 1,
          start: parseInt(pName[index]["end"]) + 1,
          end: "",
          price: 0,
        });
      } else if (
        parseInt(pName[index]["end"]) < parseInt(pName[index]["start"]) ||
        (pName[index + 1]["end"] != "" &&
          parseInt(pName[index]["end"]) > parseInt(pName[index + 1]["end"]) - 1)
      ) {
        pName[index]["end"] = parseInt(pName[index + 1]["start"]) - 1;
      } else {
        pName[index + 1]["start"] = parseInt(pName[index]["end"]) + 1;
      }
      },
      CheckPackingHeader(packing) {
        var postBoxHeader = [];
        if (packing === 2) {
          postBoxHeader = [
            {align: "center",text: "ลำดับที่",value: "orderNo",sortable: false,width: "100px",},
            {align: "left",text: "กล่อง",value: "name",sortable: false,width: "200px",},
            {align: "center",text: "กว้าง ",value: "cubic1",sortable: false,width: "120px",},
            {align: "center",text: "ยาว",value: "cubic2",sortable: false,width: "120px",},
            {align: "center",text: "สูง",value: "cubic3",sortable: false,width: "120px",},
            {align: "center",text: "ต้นทุนกล่อง",value: "boxCostPrice",sortable: false,width: "130px",},
            {align: "center",text: "ราคาค่าแพ็ค + ค่าบรรจุภัณฑ์",value: "packAndBoxPrice",sortable: false,width: "130px",},
            {align: "center",text: "ราคาBubble",value: "bubblePrice",sortable: false,width: "130px",},
            // {align: "center",text: "ลบ",value: "delete",sortable: false,width: "90px",},
          ];
        } else {
          postBoxHeader = [
            {align: "center",text: "ลำดับที่",value: "orderNo",sortable: false,width: "100px",},
            {align: "left",text: "กล่อง",value: "name",sortable: false,width: "180px",},
            {align: "center",text: "กว้าง ",value: "cubic1",sortable: false,width: "100px",},
            {align: "center",text: "ยาว",value: "cubic2",sortable: false,width: "100px",},
            {align: "center",text: "สูง",value: "cubic3",sortable: false,width: "100px",},
            {align: "center",text: "ต้นทุนกล่อง",value: "boxCostPrice",sortable: false,width: "100px",},
            {align: "center",text: "ราคาขายกล่อง",value: "boxSellPrice",sortable: false,width: "100px",},
            {align: "center",text: "ราคาค่าแพ็ค",value: "packPrice",sortable: false,width: "100px",},
            {align: "center",text: "ราคาBubble",value: "bubblePrice",sortable: false,width: "100px",},
            // {align: "center",text: "ลบ",value: "delete",sortable: false,width: "90px",},
          ];
        }
        return postBoxHeader;
      },
      CheckEmpty(v) {
        if (v == null) {
          return false;
        } else if (v == 0) {
          return false;
        } 
        else if (v.toString() == "") {
          return false;
        } else {
          return true;
        }
      },
      AddPostBox(pName) {
        pName.push({
          // key: pName.length == 0 ? 0 : pName[pName.length - 1]["key"] + 1,
          id: 0,
          name: "",
          cubic1: null,
          cubic2: null,
          cubic3: null,
          branchExpressID:0,
          shopExpressID:0,
          boxCostPrice: null,
          boxSellPrice: null,
          packPrice: null,
          packAndBoxPrice: null,
          bubblePrice: null,
          expressCompanyID: 0,
          orderNo: pName.length + 1,
          branchId: 0,
          shopId: localStorage.getItem('shop_id'),
          parentID: 0,
          
        });
      },
      RemovePostBox(pName, index) {
        Swal.fire({
          icon: "warning",
          title: "ลบ ใช่ หรือ ไม่",
          text: "ต้องการลบ " + pName[index]["name"] + " ใช่ หรือ ไม่",
          confirmButtonText: "Yes!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.value) {
            // SetOrderNo
            var set = []
            for (var i in pName) {
              if (parseInt(i) >= index) {
                pName[i]["orderNo"] = parseInt(pName[i]["orderNo"]) - 1;
                this.packsetting.removeItem.push(pName[i].id)
              }
            }
            pName.splice(index, 1);
          }
        });
      },
      SetOrderNo(pName, index) {
      if (
        parseInt(pName[index]["orderNo"]) > 0 &&
        parseInt(pName[index]["orderNo"]) <= pName.length
      ) {
        var num = [index, index];

        // หาตำแหน่งที่จะไปแทนที่
        for (var i in pName) {
          if (
            parseInt(index) != parseInt(i) &&
            parseInt(pName[index]["orderNo"]) == pName[i]["orderNo"]
          ) {
            num[1] = parseInt(i);
            break;
          }
        }
        if (num[0] != num[1]) {
          // ลบตำแหน่งตัวเอง
          var list = pName[index];
          pName.splice(index, 1);

          // เพิ่มตำแหน่งที่จะไป
          pName.splice(num[1], 0, list);

          // เรียง orderNo ใหม่
          for (var t in pName) {
            pName[t]["orderNo"] = parseInt(t) + 1;
          }
        }
      }
      },
      SetRulesInterExpress(){
      this.interExpressOption.rulesServiceCode = [(v) => Trim_rules(v) || 'กรุณาระบุ']
      this.interExpressOption.rulesServiceControlCode = [(v) => Trim_rules(v) || 'กรุณาระบุ']
      this.interExpressOption.rulesTemperatureControlCode = [(v) => Trim_rules(v) || 'กรุณาระบุ']
      this.interExpressOption.rulesTemperatureTypeCode = [(v) => Trim_rules(v) || 'กรุณาระบุ']
      },
      ClearRules () {
        this.my_express_edit.rulesAccount = []
        this.my_express_edit.rulesCodPersent = []
        this.my_express_edit.rulesExpress = []
        this.my_express_edit.expressValidateText = ''
        this.my_express_edit.accountValidateText = ''
        this.my_express_edit.codPersentValidateText = ''
        this.interExpressOption.rulesServiceCode = []
        this.interExpressOption.rulesServiceControlCode = []
        this.interExpressOption.rulesTemperatureControlCode = []
        this.interExpressOption.rulesTemperatureTypeCode = []
      },
      checklog (itemID) {
      console.log(itemID);
      // console.log('%c'+itemID,
      // `background: #000000; color: #FFF; padding: 4px; border-radius: 2px`
      // );
      },
      get_languages,
      Trim_value,
      Trim_rules,
      isNumberWithDot,
      FormatInvoice,
      formatMoney,
      isNumberWNoDot,
      set_format_date_time,
      format_price,
      format_vat_type,
      AlertSuccess,
      AlertWarning,
      AlertError,
    
    },
  }
</script>
<style scoped>
.some-other-style {
  background: blue;
}
.my-header-style {
  border-bottom: 1px solid #CFD8DC;
  padding-top: 4px;
}
.my-header-child-style{
    padding-top: 2px !important;
    padding-bottom: 2px !important ;
}
.my-header-child2-style{
    padding-top: 8px !important;
    padding-bottom: 8px !important ;
}
.body-style{
    border-right: 1px solid #CFD8DC;
}
</style>